import React, { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { PadDetails } from "../../modules";

export const padTabsKeys = {
  contact: "contact-tab",
  landing: "landing-tab",
  reviews: "reviews-tab",
};

export interface PadViewParams {
  id: string;
  token: string;
}

export const PadView: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = React.useState(false);
  const [params, setParams] = React.useState<PadViewParams>({} as PadViewParams);

  useEffect(() => {
    const id = searchParams.get("id");
    const token = searchParams.get("token");

    if (id && token) setParams({ id, token });
    else setError(true);
  }, [searchParams]);

  if (error) {
    return <Navigate to="/" />;
  }

  return (
    <div className="pad-view flex min-h-full items-start justify-center p-3 py-0 pt-1">
      <div className="h-full w-full max-w-6xl">
        <PadDetails params={params} />
      </div>
    </div>
  );
};
