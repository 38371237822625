import React from "react";
import Lightbox from "react-image-lightbox";
import { IUpload } from "../../interfaces";
import { cs } from "../../utils";

interface ImagesPreviewProps {
  images: IUpload[];
  size?: "small" | "default" | "large";
  className?: string;
}

const imagesPreviewSizes = {
  default: "w-32 sm:w-36",
  small: "w-24 sm:w-28",
  large: "w-40 sm:w-48",
};

export const ImagesPreview: React.FC<ImagesPreviewProps> = (props) => {
  const { images, size = "default", className } = props;
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isPreviewActive, setIsPreviewActive] = React.useState(false);

  const onImageClick = (index: number) => () => {
    setCurrentIndex(index);
    setIsPreviewActive(true);
  };

  return (
    <>
      <div className={cs("flex flex-wrap gap-2", className)}>
        {images.map((img, idx) => (
          <div
            key={img.id}
            className={cs(
              "flex items-center justify-center overflow-hidden rounded-md bg-slate-100",
              imagesPreviewSizes[size]
            )}
            style={{ aspectRatio: "4 / 3" }}
            role="button"
            aria-hidden="true"
            onClick={onImageClick(idx)}
          >
            <img alt={img.title} src={img.file_url} className="object-cover" />
          </div>
        ))}
      </div>
      {isPreviewActive && (
        <Lightbox
          mainSrc={images[currentIndex].file_url}
          nextSrc={images[(currentIndex + 1) % images.length].file_url}
          prevSrc={images[(currentIndex + images.length - 1) % images.length].file_url}
          mainSrcThumbnail={images[currentIndex]?.title}
          nextSrcThumbnail={images[(currentIndex + 1) % images.length]?.title}
          prevSrcThumbnail={images[(currentIndex + images.length - 1) % images.length]?.title}
          onCloseRequest={() => setIsPreviewActive(false)}
          onMovePrevRequest={() => setCurrentIndex((currentIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setCurrentIndex((currentIndex + 1) % images.length)}
        />
      )}
    </>
  );
};
