import React from "react";
import { Description } from "../../components";
import PadDetailsContext from "./PadDetailsContext";

export const PadDetailsLanding: React.FC = () => {
  const { pad, landingFees } = React.useContext(PadDetailsContext);
  const [SON, setSON] = React.useState<string>("Landing Instructions");

  React.useEffect(() => {
    if (pad?.is_landing_advice_displayed) {
      setSON("Landing Instructions");
    } else {
      setSON("Landing Advice");
    }
  }, [pad?.is_landing_advice_displayed]);

  return (
    <Description>
      <Description.Item label="Site Description" contentClassName="whitespace-pre-wrap">
        {pad?.site_information}
      </Description.Item>
      <Description.Item contentClassName="whitespace-pre-wrap" label={SON}>
        {pad?.landing_advice}
      </Description.Item>
      {pad?.category_id !== 7 ? (
        <Description.Item
          label={
            <div className="flex flex-col">
              <span>Establishment Warnings</span>
              <a
                href="https://web-staging.helipaddy.com/blog/2016/04/13/establishment-checkbox-help"
                className="block text-sm text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                Need Help?
              </a>
            </div>
          }
        >
          <div className="flex h-full flex-wrap items-center gap-2">
            {pad?.warnings?.map((itm) => (
              <div
                className="leading-sm inline-flex items-center rounded-md border bg-gray-50 px-3 py-1 text-xs font-medium text-gray-700"
                key={`warning-${itm.id}`}
              >
                {itm.display_name}
              </div>
            ))}
          </div>
        </Description.Item>
      ) : (
        <Description.Item
          label={
            <div className="flex flex-col">
              <span>Airfield facilities</span>
              <a
                href="https://web-staging.helipaddy.com/blog/2016/04/13/aerodrome-checkbox-help"
                className="block text-sm text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                Need Help?
              </a>
            </div>
          }
        >
          <div>
            {!!pad?.warnings && (
              <div className="mb-2 flex h-full flex-wrap items-center gap-2">
                {pad?.warnings?.map((itm) => (
                  <div
                    className="leading-sm ml-4 inline-flex items-center rounded-full border bg-white px-3 py-1 text-xs font-bold uppercase text-gray-700"
                    key={`warning-${itm.id}`}
                  >
                    {itm.display_name}
                  </div>
                ))}
              </div>
            )}
            <div>
              Landing fees category: {landingFees?.[pad?.landing_fees || 0]}
              <br />
              ICAO Code: {pad?.icao}
            </div>
          </div>
        </Description.Item>
      )}
    </Description>
  );
};
