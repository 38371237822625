import React from "react";
import { ReactComponent as StartIcon } from "../../asstets/icons/start.svg";
import { ReactComponent as StartHalfIcon } from "../../asstets/icons/start-half.svg";

export type StarTypes = "default" | "full" | "half";

interface StarProps {
  type: StarTypes;
  size: number;
}

export const Star: React.FC<StarProps> = (props) => {
  const { size, type } = props;

  if (type === "full") {
    return <StartIcon className="text-star" width={size} />;
  }

  if (type === "half") {
    return (
      <div className="relative">
        <StartHalfIcon className="relative left-0 z-20 text-star" width={size} />
        <StartIcon className="absolute top-0 left-0 z-10 text-zinc-300" width={size} />
      </div>
    );
  }

  return <StartIcon className="text-zinc-300" width={size} />;
};
