import React from "react";
import PilotPageLayout from "../../layouts/PilotPageLayout";
import smartphoneImage from "../../asstets/images/smartphone.png";
import { Button } from "../../components";

export const SoSorry: React.FC = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true);
    }
  }, []);

  return (
    <PilotPageLayout>
      <div className="flex flex-col items-center justify-center">
        <div className="mb-6 flex h-32 w-32 items-center  justify-center rounded-full bg-white p-8">
          <img alt="Helipaddy App" className="h-full w-full" src={smartphoneImage} />
        </div>
        <p className="max-w-sm text-center text-lg text-white">
          Please open this link from a device with the <span className="font-medium">Helipaddy App</span> installed, and
          you&apos;ll be able to view the destination!
        </p>
        {isMobile && (
          <Button type="primary">
            <a href="http://onelink.to/j6jka5">Open Helipaddy</a>
          </Button>
        )}
      </div>
    </PilotPageLayout>
  );
};
