import React from "react";
import bgImage from "../asstets/images/pilot-page-bg.jpg";
import { ReactComponent as LogoIcon } from "../asstets/icons/logo.svg";
import { ReactComponent as FacebookIcon } from "../asstets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../asstets/icons/instagram.svg";

const PilotPageLayout: React.FC = (props) => (
  <div className="flex h-full flex-col justify-between">
    <header className="py-6 px-5">
      <div className="mx-auto max-w-5xl">
        <a href="https://helipaddy.com">
          <LogoIcon />
        </a>
      </div>
    </header>
    <main
      className="relative flex flex-1 items-center justify-center bg-cover py-8 after:absolute after:h-full after:w-full after:bg-black after:bg-opacity-60 after:backdrop-blur-sm"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        minHeight: 420,
      }}
    >
      <div className="relative z-10">{props.children}</div>
    </main>
    <footer className="px-1">
      <div className="flex flex-col items-center justify-center border-b-[1px] border-b-zinc-200 py-6">
        <h5 className="mb-5">Stay Connected</h5>
        <div className="flex">
          <a className="mx-4 ml-2" href="https://www.facebook.com/helipaddyapp/">
            <FacebookIcon />
          </a>
          <a className="mx-4" href="https://www.instagram.com/helipaddy/">
            <InstagramIcon />
          </a>
        </div>
      </div>
      <div className="mx-auto mt-2 flex w-full max-w-6xl flex-col items-center justify-between py-6 lg:flex-row">
        <div className="mb-6 text-center lg:mb-0">
          <p>Copyright © 2022 Helipaddy Limited.</p>
          <p>Company Number 08866916</p>
          <a
            href="https://helipaddy.com/terms-and-conditions/"
            rel="noopener noreferrer"
            className="mt-4 block text-primary"
          >
            Terms of Use
          </a>
        </div>
        <div className="mb-6 text-center lg:mb-0">
          <p>Helipaddy, The POD Building, Bridges Court</p>
          <p>Road, Battersea, London,</p>
          <div className="mt-4 flex">
            <p>SW11 3BE&nbsp;</p>
            <a href="tel:+44 20 3637 7595" rel="noopener noreferrer" className=" block text-primary">
              +44 20 3637 7595
            </a>
            &nbsp;/&nbsp;
            <a href="mailto:support@helipaddy.com" rel="noopener noreferrer" className=" block text-primary">
              support@helipaddy.com
            </a>
          </div>
        </div>
        <div className="text-center">
          <p>Copyright © 2022 Helipaddy Limited.</p>
          <a href="https://helipaddy.com/api/" rel="noopener noreferrer" className="mt-4 block text-primary">
            Helipaddy API access
          </a>
        </div>
      </div>
    </footer>
  </div>
);

export default PilotPageLayout;
