import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Activation, Free3Premium, PadView, ResetPassword, SoSorry } from "./pages";
import { NotFound } from "./pages/NotFound";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000 * 5, // 5 minutes
      retry: false,
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/pad" element={<PadView />} />
          <Route path="/so-sorry/:pad_id" element={<SoSorry />} />
          <Route path="/free-3-premium" element={<Free3Premium />} />
          <Route path="/account/activation" element={<Activation />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
