import React from "react";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Alert, Comment, CommentSkeleton, Stars } from "../../components";
import { getPadReviews } from "../../services/api";
import PadDetailsContext from "./PadDetailsContext";
import emptyFolder from "../../asstets/images/empty-folder.png";

dayjs.extend(relativeTime);

export const PadDetailsReview: React.FC = () => {
  const { params } = React.useContext(PadDetailsContext);
  const {
    data: reviews,
    error,
    isLoading: loading,
  } = useQuery(["pad-reviews", params.id], getPadReviews(params.id, params.token), { enabled: !!params.id });
  const [isEmpty, setIsEmpyt] = React.useState(false);

  React.useEffect(() => {
    if (reviews?.stars.total_reviews === 0 && reviews.stars.rating === 0) {
      setIsEmpyt(true);
    } else {
      setIsEmpyt(false);
    }
  }, [reviews, loading]);

  if (error) {
    return (
      <div className="flex w-full items-center justify-center">
        <Alert type="error" title="Error" description={error as string} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center">
      {isEmpty ? (
        <div className="flex flex-col items-center justify-center pt-3">
          <img alt="No data" width={80} src={emptyFolder} />
          <h3 className="mr-3">No data</h3>
        </div>
      ) : (
        <>
          {!loading && (
            <div className="top-0 z-30 flex flex-col items-center justify-center bg-white pt-2 md:sticky">
              <div className="flex flex-wrap items-center justify-center gap-3">
                <Stars value={reviews?.stars.landing} label="for ease of landings" />
                <Stars value={reviews?.stars.helifriendliness} label="for heli-friendliness" />
                <Stars value={reviews?.stars.food} label="for food" />
                <Stars value={reviews?.stars.service} label="for for food" />
              </div>
              <div className="mt-3 w-full max-w-xl">
                <h4 className="pb-1 text-center text-xs">
                  <span className="font-medium">{reviews?.stars.visits}%</span> would visit again
                </h4>
                <div className="w-full rounded-full bg-zinc-100">
                  <div
                    className="overflow-hidden rounded-full bg-green-600 p-0.5 text-center text-xs font-medium leading-none text-blue-100"
                    style={{ width: `${reviews?.stars.visits}%` }}
                  >
                    {reviews?.stars.visits}%
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="mt-14 grid w-full max-w-2xl grid-flow-row grid-cols-1 gap-6">
            {loading ? (
              <>
                <CommentSkeleton />
                <CommentSkeleton />
                <CommentSkeleton />
              </>
            ) : (
              reviews?.reviews.map((review) => (
                <Comment
                  key={review.id}
                  user={review.user}
                  created_at={review.created_at}
                  comment={review.comment}
                  uploads={review.uploads}
                  rating={review.rating}
                />
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};
