import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Input } from "../../components";

export interface ResetPasswordFormValues {
  password: string;
  password_confirmation: string;
}

interface ResetPasswordFormProps {
  onSubmit: (values: ResetPasswordFormValues) => Promise<void>;
}

const schema = yup.object().shape({
  password: yup.string().min(6).required(),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords do not match")
    .required(),
});

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const { onSubmit } = props;
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ResetPasswordFormValues>({
    resolver: yupResolver(schema),
  });

  const submitHandler = React.useCallback(
    async (values: ResetPasswordFormValues) => {
      try {
        await onSubmit(values);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [onSubmit]
  );

  return (
    <div>
      <h3 className="mb-4 text-2xl font-medium text-white">Create new password</h3>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Input {...register("password")} placeholder="Password" autoComplete="off" type="password" />
        {errors.password && <p className="pl-1 text-sm text-red-500">{errors.password?.message}</p>}
        <Input
          {...register("password_confirmation")}
          placeholder="Confirm Password"
          type="password"
          autoComplete="off"
          className="mt-4"
        />
        {errors.password_confirmation && (
          <p className="pl-1 text-sm text-red-500">{errors.password_confirmation?.message}</p>
        )}
        <div className="mt-4 flex justify-end">
          <Button htmlType="submit" loading={loading} className="w-80">
            Reset Password
          </Button>
        </div>
      </form>
    </div>
  );
};
