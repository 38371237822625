import React from "react";
import { cs } from "../../utils";

import "./index.css";

export interface InputProps extends Omit<React.InputHTMLAttributes<any>, "size"> {
  className?: string;
  size?: "default";
  label?: string;
  inputClassName?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { className, size = "default", inputClassName, name, label, ...rest } = props;

  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <input id={name} name={name} className={cs("input", inputClassName, size)} ref={ref} {...rest} />
    </div>
  );
});
