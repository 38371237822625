import React from "react";
import { useSearchParams } from "react-router-dom";
import PilotPageLayout from "../../layouts/PilotPageLayout";
import { Button } from "../../components";
import { ReactComponent as SpinIcon } from "../../asstets/icons/spinner.svg";
import { ReactComponent as SmileIcon } from "../../asstets/icons/smile.svg";
import { ReactComponent as SadIcon } from "../../asstets/icons/sad.svg";
import API from "../../services/api/config";

export const Activation: React.FC = () => {
  const [params] = useSearchParams();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true);
    }
  }, []);

  const activationHandler = async (token: string) => {
    try {
      setLoading(true);
      await API.get(`/users/verify-email?token=${token}`);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const token = params.get("token");

    if (token) {
      activationHandler(token);
    } else {
      setError(true);
    }
  }, [params]);

  const Content = React.useMemo(() => {
    if (error) {
      return (
        <>
          <SadIcon width={86} />
          <h2 className="mb-4 text-2xl font-medium text-white">No user found / Invalid activation link</h2>
          <p className="mb-5 max-w-sm text-center text-lg text-white">
            The activation link you followed is no longer valid. This is probably because link expiried.
          </p>
        </>
      );
    }

    if (loading) {
      return (
        <div className="flex flex-col items-center justify-center">
          <SpinIcon className="h-10 w-10 animate-spin text-white" />
          <h3 className="mt-2 text-lg font-medium text-white">Activating...</h3>
        </div>
      );
    }

    return (
      <>
        <SmileIcon width={86} />
        <h2 className="mb-4 text-2xl font-medium text-white">Congratulations</h2>
        <p className="mb-5 max-w-sm text-center text-lg text-white">
          Thank you - your email address has been verified. Please continue the registration process in the mobile app
        </p>
        {isMobile && (
          <Button type="primary">
            <a href="http://onelink.to/j6jka5">Open Helipaddy</a>
          </Button>
        )}
      </>
    );
  }, [error, isMobile, loading]);

  return (
    <PilotPageLayout>
      <div className="flex flex-col items-center justify-center">{Content}</div>
    </PilotPageLayout>
  );
};
