import axios, { AxiosError } from "axios";
import API from "./config";

interface ResetPassBody {
  password: string;
  password_confirmation: string;
  token: string;
  email: string;
}

export const resetPassword = async (data: ResetPassBody) =>
  API.post("/password/reset", data)
    .then((res) => res.data)
    .catch((error: Error | AxiosError<{ message: string }>) => {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error.response?.data.message);
      }

      if (error.message) {
        return Promise.reject(error.message);
      }

      return Promise.reject("Unknow error");
    });
