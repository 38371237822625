import React from "react";
import { cs } from "../../utils";
import { DescriptionRow } from "./DescirptionRow";

import "./index.css";

export interface DescriptionProps {
  layout?: "horizontal" | "vertical";
  className?: string;
}

interface DescriptionParts {
  Item: typeof DescriptionRow;
}

export const Description: React.FC<DescriptionProps> & DescriptionParts = (props) => {
  const { layout, children, className } = props;

  return <div className={cs("decription", layout, className)}>{children}</div>;
};

Description.Item = DescriptionRow;
