import React from "react";
import { cs } from "../../utils";
import TabsContext from "./TabsContext";

export interface TabsNodeProps {
  onClick: (key: string) => void;
  title: string;
  dataKey: string;
  disabled?: boolean;
}

export const TabsNode: React.FC<TabsNodeProps> = (props) => {
  const { activeTab } = React.useContext(TabsContext);
  const [active, setActive] = React.useState(false);

  React.useEffect(() => setActive(activeTab === props.dataKey), [activeTab, props.dataKey]);

  const clickHanlder = () => props.onClick(props.dataKey);

  return (
    <li className="mr-2">
      <button
        className={cs("tabs-node", active && "active", props.disabled && "disabled")}
        type="button"
        onClick={clickHanlder}
      >
        {props.title}
      </button>
    </li>
  );
};
