import React from "react";
import { cs } from "../../utils";
import TabsContext from "./TabsContext";

export interface TabsPaneProps {
  dataKey: string;
}

export const TabsPane: React.FC<TabsPaneProps> = (props) => {
  const { activeTab } = React.useContext(TabsContext);
  const [active, setActive] = React.useState(false);

  React.useEffect(() => setActive(activeTab === props.dataKey), [activeTab, props.dataKey]);

  return <div className={cs("tab-pane", active && "active")}>{props.children}</div>;
};
