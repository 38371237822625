import React from "react";
import { Description, ImagesPreview } from "../../components";
import PadDetailsContext from "./PadDetailsContext";

export const PadDetailsContact: React.FC = () => {
  const { pad, categories, statuses, contactTypes } = React.useContext(PadDetailsContext);

  return (
    <Description>
      <Description.Item label="Name of pad">{pad?.name}</Description.Item>
      <Description.Item label="Category">
        {categories?.find((cat) => cat.id === pad?.category_id)?.display_name}
      </Description.Item>
      <Description.Item label="Status">
        {statuses?.find((status) => status.id === pad?.status_id)?.display_name}
      </Description.Item>
      <Description.Item label="Coordinates">
        <>
          <div>
            <span className="text-gray-600">Latitude:</span>
            <span className="ml-1">{pad?.location?.latitude}</span>
          </div>
          <div>
            <span className="text-gray-600">Longitude:</span>
            <span className="ml-1">{pad?.location?.longitude}</span>
          </div>
          <div>
            <span className="text-gray-600">Address:</span>
            <span className="ml-1">{pad?.location?.name}</span>
          </div>
          <div>
            <span className="text-gray-600">County:</span>
            <span className="ml-1">
              {pad?.location?.county} {pad?.location?.county && "(from Google Maps)"}
            </span>
          </div>
          <div>
            <span className="text-gray-600">Region:</span>
            <span className="ml-1">{pad?.location?.region}</span>
          </div>
        </>
      </Description.Item>
      {!!pad?.contacts?.length && (
        <Description.Item label="Contacts" contentClassName="contacts-grid">
          {pad?.contacts?.map((user) => (
            <div key={user.id} className="overflow-hidden">
              <div>
                <span className="text-gray-600">Name:</span>
                <span className="ml-1">{user.name}</span>
              </div>
              <div className="overflow-hidden text-ellipsis">
                <span className="text-gray-600">Email:</span>
                <a className="ml-1" href={`mailto:${user.email}`}>
                  {user.email}
                </a>
              </div>
              <div>
                <span className="text-gray-600">Phone:</span>
                <a className="ml-1" href={`tel:${user.phone}`}>
                  {user.phone && `+${user.phone}`}
                </a>
              </div>
              <div>
                <span className="text-gray-600">Contact type:</span>
                <span className="ml-1">
                  {contactTypes?.find((type) => type.id === user.contact_type_id)?.display_name}
                </span>
              </div>
            </div>
          ))}
        </Description.Item>
      )}
      {!!pad?.uploads?.length && (
        <Description.Item label="Photos" contentClassName="flex flex-wrap gap-2">
          <ImagesPreview images={pad.uploads} />
        </Description.Item>
      )}
    </Description>
  );
};
