import React from "react";
import { useSearchParams } from "react-router-dom";
import PilotPageLayout from "../../layouts/PilotPageLayout";
import { Button } from "../../components";
import { ReactComponent as SmileIcon } from "../../asstets/icons/smile.svg";
import { ReactComponent as SadIcon } from "../../asstets/icons/sad.svg";
import { ResetPasswordForm, ResetPasswordFormValues } from "./ResetPasswordForm";
import { resetPassword } from "../../services/api";

export const ResetPassword: React.FC = () => {
  const [params] = useSearchParams();
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [resetParams, setResetParams] = React.useState<{ token: string; email: string } | null>(null);
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true);
    }
  }, []);

  const resetHandler = React.useCallback(
    async (values: ResetPasswordFormValues) => {
      if (!resetParams?.token || !resetParams.email) {
        setError(true);
        return Promise.reject();
      }

      try {
        await resetPassword({ ...values, ...resetParams });
        setSuccess(true);
        return Promise.resolve();
      } catch (err) {
        setError(true);
        return Promise.reject();
      }
    },
    [resetParams]
  );

  React.useEffect(() => {
    const token = params.get("token");
    const email = params.get("email");

    if (token && email) {
      setResetParams({ token, email });
    } else {
      setError(true);
    }
  }, [params]);

  const Content = React.useMemo(() => {
    if (error) {
      return (
        <>
          <SadIcon width={86} />
          <h2 className="mb-4 text-2xl font-medium text-white">Invalid password reset link!</h2>
          <p className="mb-5 max-w-sm text-center text-lg text-white">
            This link is no longer valid. Please click the reset password link on a device with the&nbsp;
            <span className="font-medium">Helipaddy App</span>
            &nbsp;installed!
          </p>
          {isMobile && (
            <Button type="primary">
              <a href="http://onelink.to/j6jka5">Open Helipaddy</a>
            </Button>
          )}
        </>
      );
    }

    if (success) {
      return (
        <>
          <SmileIcon width={86} />
          <h2 className="mb-4 text-2xl font-medium text-white">Successful password reset!</h2>
          <p className="mb-5 max-w-sm text-center text-lg text-white">
            You can now use your new password to log in in to your account!
          </p>
          {isMobile && (
            <Button type="primary">
              <a href="http://onelink.to/j6jka5">Open Helipaddy</a>
            </Button>
          )}
        </>
      );
    }

    return <ResetPasswordForm onSubmit={resetHandler} />;
  }, [error, isMobile, resetHandler, success]);

  return (
    <PilotPageLayout>
      <div className="flex flex-col items-center justify-center">{Content}</div>
    </PilotPageLayout>
  );
};

export * from "./ResetPasswordForm";
