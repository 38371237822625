import React from "react";
import { useQuery } from "react-query";
import { Alert, Tabs } from "../../components";
import { PadViewParams } from "../../pages";
import {
  getCategoriesList,
  getPadInfo,
  getStatusesList,
  getContactTypesList,
  getLandingFeesList,
} from "../../services/api";
import { PadDetailsContact } from "./PadDetailsContact";
import PadDetailsContext from "./PadDetailsContext";
import { PadDetailsLoading } from "./PadDetailsLoading";
import { PadDetailsLanding } from "./PadDetailsLanding";

import "./index.css";
import { PadDetailsReview } from "./PadDetailsReviews";

export interface PadDetailsProps {
  params: PadViewParams;
}

export const PadDetails: React.FC<PadDetailsProps> = ({ params }) => {
  const [activeKey, setActiveKey] = React.useState("contact");
  const {
    data: pad,
    error: padError,
    isLoading: padLoading,
  } = useQuery(["pad", params.id], getPadInfo(params.id, params.token), { enabled: !!params.id });
  const { data: categories, isLoading: categoriesLoading } = useQuery("categories", getCategoriesList);
  const { data: statuses, isLoading: statusesLoading } = useQuery("statuses", getStatusesList);
  const { data: contactTypes, isLoading: contactTypesLoading } = useQuery("contact-types", getContactTypesList);
  const { data: landingFees, isLoading: landingFeesLoading } = useQuery("landing-fees", getLandingFeesList);

  const updateTabKey = React.useCallback((key: string) => setActiveKey(key), []);

  if (padError) {
    return (
      <div className="flex w-full items-center justify-center">
        <Alert type="error" title="Error" description={padError as string} />
      </div>
    );
  }

  if (padLoading || categoriesLoading || statusesLoading || contactTypesLoading || landingFeesLoading) {
    return <PadDetailsLoading />;
  }

  return (
    <div className="pad-details">
      <Tabs activeKey={activeKey}>
        <Tabs.Nav>
          <Tabs.Node onClick={updateTabKey} title="Contact" dataKey="contact" />
          <Tabs.Node onClick={updateTabKey} title="Landing" dataKey="landing" />
          <Tabs.Node onClick={updateTabKey} title="Reviews" dataKey="reviews" />
        </Tabs.Nav>
        <Tabs.Content>
          <PadDetailsContext.Provider value={{ pad, categories, statuses, contactTypes, landingFees, params }}>
            <Tabs.Pane dataKey="contact">
              <PadDetailsContact />
            </Tabs.Pane>
            <Tabs.Pane dataKey="landing">
              <PadDetailsLanding />
            </Tabs.Pane>
            <Tabs.Pane dataKey="reviews">
              <PadDetailsReview />
            </Tabs.Pane>
          </PadDetailsContext.Provider>
        </Tabs.Content>
      </Tabs>
    </div>
  );
};
