import dayjs from "dayjs";
import React from "react";
import { ImagesPreview, Stars } from "..";
import { IUpload } from "../../interfaces";

interface CommentProps {
  user: {
    first_name: string;
    last_name: string;
    name: string;
  };
  created_at: string;
  comment: string;
  rating: number;
  uploads: IUpload[];
}

export const Comment: React.FC<CommentProps> = (props) => {
  const { comment, created_at, user, rating, uploads } = props;
  return (
    <div className="flex w-full items-start justify-start">
      <div>
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-slate-500 indent-0 font-medium uppercase leading-none text-white">
          AZ
        </div>
      </div>
      <div className="ml-3">
        <div className="flex items-center">
          <h3 className="font-medium">{user.name}</h3>
          <span className="ml-1 text-xs text-zinc-600">{dayjs(created_at).fromNow()}</span>
        </div>
        <Stars value={rating} className="!bg-transparent !px-0 !pt-1" size={16} />
        <p className="mt-1 text-base leading-tight">{comment}</p>
        <ImagesPreview images={uploads} size="small" className="mt-3" />
      </div>
    </div>
  );
};

export { CommentSkeleton } from "./CommentSkeleton";
