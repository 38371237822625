import React from "react";

export const CommentSkeleton: React.FC = () => (
  <div className="flex w-full animate-pulse items-start justify-start">
    <div>
      <div className="flex h-12 w-12 items-center justify-center rounded-full  bg-transparent bg-zinc-200 indent-0 text-base font-medium uppercase leading-none  text-zinc-200">
        AZ
      </div>
    </div>
    <div className="ml-3">
      <div className="flex items-center">
        <h3 className="mb-2 h-4 rounded bg-zinc-200 font-medium leading-none text-zinc-200" style={{ width: 135 }}>
          Header
        </h3>
      </div>
      <p className="mt-1 h-3 rounded bg-zinc-200 text-base leading-none text-zinc-200" style={{ width: 235 }} />
      <p className="mt-1 h-3 rounded bg-zinc-200 text-base leading-none text-zinc-200" style={{ width: 335 }} />
      <p className="mt-1 h-3 rounded bg-zinc-200 text-base leading-none text-zinc-200" style={{ width: 155 }} />
    </div>
  </div>
);
