import React from "react";
import TabsContext from "./TabsContext";
import { TabsPane } from "./TabsPane";
import { TabsNavList } from "./TabsNavList";
import { TabsNode } from "./TabsNode";
import { TabsContent } from "./TabsContent";

import "./index.css";

interface TabsProps {
  activeKey: string;
}

interface TabsParts {
  Nav: typeof TabsNavList;
  Node: typeof TabsNode;
  Pane: typeof TabsPane;
  Content: typeof TabsContent;
}

export const Tabs: React.FC<TabsProps> & TabsParts = (props) => {
  const [activeTab, setActiveTab] = React.useState(props.activeKey);

  React.useEffect(() => setActiveTab(props.activeKey), [props.activeKey]);

  const contextValue = React.useMemo(() => ({ activeTab }), [activeTab]);

  return (
    <TabsContext.Provider value={contextValue}>
      <div className="tabs">{props.children}</div>
    </TabsContext.Provider>
  );
};

Tabs.Nav = TabsNavList;
Tabs.Node = TabsNode;
Tabs.Content = TabsContent;
Tabs.Pane = TabsPane;
