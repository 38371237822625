import React from "react";
import { cs } from "../../utils";

export interface DescriptionRowProps {
  label: string | JSX.Element;
  contentClassName?: string;
}

export const DescriptionRow: React.FC<DescriptionRowProps> = (props) => {
  const { label, children, contentClassName } = props;

  return (
    <div className="decription-row">
      <div className="description-row-label">
        <h4>{label}</h4>
      </div>
      <div className={cs("descirption-row-content", contentClassName)}>{children}</div>
    </div>
  );
};
