import { createContext } from "react";
import { FeesObj, IOption, IPad } from "../../interfaces";
import { PadViewParams } from "../../pages";

export interface PadDetailsContextProps {
  pad: IPad | undefined;
  categories: IOption[] | undefined;
  statuses: IOption[] | undefined;
  contactTypes: IOption[] | undefined;
  landingFees: FeesObj | undefined;
  params: PadViewParams;
}

export default createContext<PadDetailsContextProps>({} as PadDetailsContextProps);
