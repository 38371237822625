import axios, { AxiosError } from "axios";
import API from "./config";
import { IPad, IReview } from "../../interfaces";

export const getPadInfo = (id: string, token: string) => async () =>
  API.get<{ data: IPad }>(`external-api/pads/${id}`, {
    headers: {
      authorization: token,
    },
  })
    .then((res) => res.data.data)
    .catch((error: Error | AxiosError<{ message: string }>) => {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error.response?.data.message);
      }

      if (error.message) {
        return Promise.reject(error.message);
      }

      return Promise.reject("Unknow error");
    });

export const getPadReviews = (id: string, token: string) => async () =>
  API.get<IReview>(`/external-api/pads/reviews/${id}`, {
    headers: {
      authorization: token,
    },
  })
    .then((res) => res.data)
    .catch((error: Error | AxiosError<{ message: string }>) => {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error.response?.data.message);
      }

      if (error.message) {
        return Promise.reject(error.message);
      }

      return Promise.reject("Unknow error");
    });
