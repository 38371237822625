import React from "react";
import { cs, uid } from "../../utils";
import { Star, StarTypes } from "./Star";

interface StarsProps {
  label?: string;
  value: string | number | undefined;
  className?: string;
  size?: number;
}

export const Stars: React.FC<StarsProps> = (props) => {
  const { label, size = 24, className, value } = props;
  const [stars, setStars] = React.useState<[StarTypes, string][]>([]);

  React.useEffect(() => {
    const starsToRender = [] as [StarTypes, string][];

    if (value) {
      const [whole, half] = value.toString().split(".").map(Number);

      for (let i = 0; i < whole; i++) {
        starsToRender.push(["full", uid(4)]);
      }

      if (half && half >= 5) {
        starsToRender.push(["half", uid(4)]);
      }

      for (let i = starsToRender.length; i < 5; i++) {
        starsToRender.push(["default", uid(4)]);
      }
    } else {
      for (let i = 0; i < 5; i++) {
        starsToRender.push(["default", uid(4)]);
      }
    }

    setStars(starsToRender);
  }, [value]);

  return (
    <div className="inline-block">
      <h4 className="pb-1 text-center text-xs">
        {!!label && (
          <>
            <span className="mr-1 font-medium">{value}</span>
            {label}
          </>
        )}
      </h4>
      <div className={cs("flex gap-1 rounded-full bg-zinc-100 px-3 py-2", className)}>
        {stars.map((type) => (
          <Star key={type[1]} type={type[0]} size={size} />
        ))}
      </div>
    </div>
  );
};
