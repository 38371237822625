import React from "react";
import { cs } from "../../utils";
import { ReactComponent as SpinIcon } from "../../asstets/icons/spinner.svg";

import "./index.css";

export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<any>, "type"> {
  className?: string;
  size?: "default";
  htmlType?: "button" | "submit" | "reset";
  type?: "primary";
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> & Omit<React.ButtonHTMLAttributes<any>, "type"> = (props) => {
  const { className, size = "default", htmlType = "button", children, type = "primary", loading, ...rest } = props;

  return (
    <button className={cs("btn", className, size, type, loading && "loading")} type={htmlType} {...rest}>
      {loading ? (
        <span className="flex items-center justify-center">
          <SpinIcon className="mr-2 h-4 w-4 animate-spin text-white" />
          <span>Loading...</span>
        </span>
      ) : (
        children
      )}
    </button>
  );
};
