import React from "react";
import PilotPageLayout from "../../layouts/PilotPageLayout";
import appstoreBadge from "../../asstets/icons/appstore.svg";
import googleplayBadge from "../../asstets/icons/googleplay.svg";
import { Button } from "../../components";

export const Free3Premium: React.FC = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true);
    }
  }, []);

  return (
    <PilotPageLayout>
      <div className="flex flex-col items-center justify-center">
        <p className="mb-5 max-w-sm text-center text-lg text-white">
          All done! We've added <span className="font-medium"> 3 months</span> of free premium access to your account!
          Scan the QR code or tap below to start exploring!
        </p>
        {isMobile ? (
          <Button type="primary">
            <a href="http://onelink.to/j6jka5">Open Helipaddy</a>
          </Button>
        ) : (
          <>
            <div className="mb-6 flex h-32 w-32 items-center  justify-center rounded-md bg-white p-2">
              <img
                alt="Helipaddy Apps"
                className="h-full w-full"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGwAAABsCAIAAAAABMCaAAABlElEQVR42u3aUQ6CQAwFQO5/ab2CpH0VutNPg7I7JLR56/VR5boQQIS4EvEK1E+L+OH6yn3T+4IIEeJ+xNKL9iZK5ffvIib2BREixLMQuxadwK38Ztu+IEKECLH4Iu/afKkhQIQIEeICRI0FIkSI/0RMBBCJwDURdkCECBFi7EDnAZ+//rQPIkSIY4jpSg/GXQ3k9r4gQoS4EnHyj0KVIXzyuxAhQjwX8S2DcSXIiAcQECFCXInYdbOuzXc1uq6QGCJEiPsR0wPzXejKw0gHIhAhQjwLMTEYJ0C7HnxpbRAhQlyJmBiqJ69JNyWIECGehdi1uEccqN9sbpEAAiJEiK9GnGwIXYN6V6MooUOECHElYhprcphPhBQQIUI8C3FyAE5Xej0QIULcj5jeTFdz6BqY2wZyiBAhrkecDETTfw54RAABESLENYjpBtIVZEyGDhAhQoTY+YKfbA6JZgIRIkSIE40lvuGmxgURIsRzEdMBRNcDmwx0IUKEeBbi5EHVvw7+24JbiBAhrkRUDaGsggjxzfUFV1Ep5wzDHlcAAAAASUVORK5CYII="
              />
            </div>
            <div className="flex">
              <a
                href="https://play.google.com/store/apps/details?id=com.helipaddy.android&amp;hl=en_GB&amp;gl=US"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2 block"
              >
                <img alt="Helipaddy Google Play" className="h-11 w-full" src={googleplayBadge} />
              </a>
              <a
                href="https://apps.apple.com/gb/app/helipaddy-heli-landing-sites/id894316463"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2 block"
              >
                <img alt="Helipaddy Google Play" className="h-11 w-full" src={appstoreBadge} />
              </a>
            </div>
          </>
        )}
      </div>
    </PilotPageLayout>
  );
};
