import API from "./config";
import { FeesObj, IOption } from "../../interfaces";

export const getContactTypesList = () => API.get<IOption[]>("/contact-types").then((res) => res.data);

export const getCategoriesList = () => API.get<IOption[]>("/categories").then((res) => res.data);

export const getStatusesList = () => API.get<IOption[]>("/statuses").then((res) => res.data);

export const getLandingFeesList = () => API.get<FeesObj>("/pads/landing-fees").then((res) => res.data);
