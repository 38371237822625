import React from "react";
import { Description, Tabs } from "../../components";

export const PadDetailsLoading: React.FC = () => (
  <div className="pad-details">
    <Tabs activeKey="contact">
      <Tabs.Nav>
        <Tabs.Node onClick={() => null} disabled title="Contact" dataKey="contact" />
        <Tabs.Node onClick={() => null} disabled title="Landing" dataKey="landing" />
        <Tabs.Node onClick={() => null} disabled title="Reviews" dataKey="reviews" />
      </Tabs.Nav>
      <Tabs.Content>
        <Tabs.Pane dataKey="contact">
          <Description className="animate-pulse">
            <Description.Item label="Name of pad">
              <div className="flex h-full items-center">
                <div className="h-4 rounded bg-zinc-200" style={{ width: 135 }} />
              </div>
            </Description.Item>
            <Description.Item label="Category">
              <div className="flex h-full items-center">
                <div className="h-4 rounded bg-zinc-200" style={{ width: 180 }} />
              </div>
            </Description.Item>
            <Description.Item label="Status">
              <div className="flex h-full items-center">
                <div className="h-4 rounded bg-zinc-200" style={{ width: 65 }} />
              </div>
            </Description.Item>
            <Description.Item label="Landing not Possible">
              <div className="flex h-full items-center">
                <div className="h-4 rounded bg-zinc-200" style={{ width: 20 }} />
              </div>
            </Description.Item>
            <Description.Item label="Coordinates">
              <div className="flex h-full flex-col items-start">
                <div className="mb-1 h-4 rounded bg-zinc-200" style={{ width: 60 }} />
                <div className="mb-1 h-4 rounded bg-zinc-200" style={{ width: 45 }} />
                <div className="mb-1 h-4 rounded bg-zinc-200" style={{ width: 90 }} />
                <div className="mb-1 h-4 rounded bg-zinc-200" style={{ width: 105 }} />
                <div className="h-4 rounded bg-zinc-200" style={{ width: 30 }} />
              </div>
            </Description.Item>
          </Description>
        </Tabs.Pane>
      </Tabs.Content>
    </Tabs>
  </div>
);
