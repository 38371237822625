import React from "react";
import logo from "../../asstets/images/logo-color.png";
import { ReactComponent as ArrowIcon } from "../../asstets/icons/arrow-right.svg";

export const NotFound: React.FC = () => (
  <div className="flex h-full flex-col items-center justify-center">
    <a href="https://helipaddy.com/" className="mb-11 block">
      <img alt="Helipaddy" src={logo} className="h-20 max-h-full w-20 max-w-full" />
    </a>
    <h5 className="font-medium uppercase text-primary">404 error</h5>
    <h1 className="text-4xl font-bold sm:text-5xl lg:text-7xl">Page not found.</h1>
    <p className="mt-2 text-center text-base font-medium text-zinc-500 sm:mt-5 sm:text-xl">
      Sorry, we couldn&apos;t find the page you&apos;re looking for.
    </p>
    <a
      href="mailto:support@helipaddy.com"
      className="mt-5 flex items-center justify-center text-xl font-medium text-primary sm:mt-7"
    >
      support@helipaddy.com <ArrowIcon height={20} className=" ml-2 translate-y-0.5" />
    </a>
  </div>
);
