import React from "react";
import { cs } from "../../utils";

import "./index.css";

export type AlertTypes = "error" | "warn";

export interface AlertProps {
  type: AlertTypes;
  title: string;
  description: string;
}

export const Alert: React.FC<AlertProps> = (props) => (
  <div className={cs("alert w-full", props.type)} role="alert">
    <p className="font-bold">{props.title}</p>
    <p>{props.description}</p>
  </div>
);
